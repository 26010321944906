import { ApiRequest } from "../shared/APIRequest";
import { AppDomainHandler } from "../shared/app-domain-handler";

export class PostDomain extends AppDomainHandler {
  #api;

  constructor() {
    super();
    this.#api = ApiRequest.factory();
  }

  async getPost(postId) {
    let result;

    try {
      result = await this.#api.getData(`/posts/${postId}`);
    } catch (err) {
      switch (err.response && err.response.status) {
        case 403:
          app.addToastMessage("Not allowed", {
            type: "error",
          });
          return false;
        case 404:
          app.addToastMessage("Post not found by UUID", { type: "error" });
          return false;
        default:
          break;
      }
    }

    if (!result.status) return false;
    return result.data;
  }

  async updatePost(postId, content) {
    let result;

    try {
      result = await this.#api.putData(`/posts/${postId}`, {content});
    } catch (err) {
      switch (err.response && err.response.status) {
        case 400:
          app.addToastMessage("Validation failed.", {
            type: "error",
          });
          return false;
        case 403:
          app.addToastMessage("Not allowed", { type: "error" });
          return false;
        case 404:
          app.addToastMessage("Entity not found by UUID", { type: "error" });
          return false;
        default:
          break;
      }
    }
    
    if (!result.status) return false;
    return result.data;
  }
}
