import {LitElement} from "lit";

customElements.define(
  "reward-animation",
  class TopBar extends LitElement {
    connectedCallback() {
      this.initConfetti();
    }

    initConfetti() {
      const jsConfetti = new JSConfetti();
      jsConfetti.addConfetti({
        emojis: ['🪙', '🎊'],
      });
    }
  }
);
