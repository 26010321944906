import { html, nothing } from "lit";
import {
  AuthenticatedMixin,
  OnboardedMixin,
  PWAPage,
} from "../../shared/pwa-page";
import { AccountDomain } from "../../domain/account-domain";
import { Task } from "@qogni-technologies/pwa-utils-library/src/utils/task";
import { AutoComplete } from "@qogni-technologies/design-system/src/components/base/form/auto-complete";
import { detectPersonNames } from "../../shared/common";
import { repeat } from "lit/directives/repeat.js";
import { ref, createRef } from "lit/directives/ref.js";
import { isMobile } from "@qogni-technologies/design-system/src/shared/common";
import { CircleDomain } from "../../domain/circle-domain";
import { ChannelDomain } from "../../domain/channel-domain";

export class PageNetwork extends OnboardedMixin(AuthenticatedMixin(PWAPage)) {
  #domain;
  #circleDomain;
  #channelDomain;
  #connectionsRef = createRef();
  #channelsRef = createRef();

  static properties = {
    filter: { type: String },
    type: { type: String },
    users: { type: Array },
    experts: { type: Array },
    suggestions: { type: Array },
    connections: { type: Array },
    connectionsPagination: { type: Object },
    connectionsPage: { type: Number },
    circles: { type: Array },
    channels: { type: Array },
    channelsPagination: { type: Object },
    channelsPage: { type: Object },
    suggestedChannels: { type: Array },
    section: { type: String },
  };

  constructor() {
    super();

    this.#domain = new AccountDomain();
    this.#circleDomain = new CircleDomain();
    this.#channelDomain = new ChannelDomain();

    this.filter = "";
    this.type = "";
    this.channelsPage = 1;
  }

  connectedCallback() {
    super.connectedCallback();
    this.fetchSuggested();

    this.section = new URLSearchParams(window.location.search).get("section");
    if (!this.section) {
      history.replaceState({}, null, "/network?section=network");
      this.section = "network";
    }
  }

  async fetchSuggested() {
    return Task.run(async () => {
      try {
        const result = await this.#domain.suggestedUsers();
        if (isMobile()) {
          this.suggestions = result.suggestions.splice(0, 2);
          this.experts = result.experts.splice(0, 2);
        } else {
          this.suggestions = result.suggestions;
          this.experts = result.experts;
        }
      } catch (e) {
        console.error(e);
        app.addToastMessage(e);
      }
    });
  }

  async fetchCircles() {
    const task = async () => {
      const response = await this.#circleDomain.getCircles();
      this.circles = response?.data;
    };

    await Task.run(task);
  }

  async fetchChannels() {
    const task = async () => {
      const result = await this.#channelDomain.list({
        per_page: 6,
        page: this.channelsPage,
      });
      this.channels = result?.data;
      this.channelsPage = result?.pagination.current_page;
      this.channelsPagination = result?.pagination;
    };

    await Task.run(task);
  }

  async fetchConnections() {
    return Task.run(async () => {
      try {
        const result = await this.#domain.getConnections({
          page: 1,
          per_page: 6,
        });
        this.connections = result.data;
        this.connectionsPagination = result.pagination;
      } catch (e) {
        console.error(e);
        app.addToastMessage(e);
      }
    });
  }

  #toggle(e) {
    if (e.newState !== "open") return;
    const targetType = e.target.dataset.type;
    if (!targetType) return;

    switch (targetType) {
      case "network":
        if (this.section !== "network") {
          history.replaceState({}, null, "/network?section=network");
          this.section = "network";
        }
        break;
      case "connections":
        if (this.section !== "connections") {
          history.replaceState({}, null, "/network?section=connections");
          this.section = "connections";
        }
        break;
      case "circles":
        if (this.section !== "circles") {
          history.replaceState({}, null, "/network?section=circles");
          this.section = "circles";
        }
        break;
      case "channels":
        if (this.section !== "channels") {
          history.replaceState({}, null, "/network?section=channels");
          this.section = "channels";
        }
        break;
      default:
        break;
    }
  }

  willUpdate(changeProps) {
    super.willUpdate(changeProps);

    if (changeProps.has("section")) {
      switch (this.section) {
        case "network":
          if (this.suggestions === undefined || this.experts === undefined)
            this.fetchSuggested();
          break;
        case "connections":
          if (this.connections === undefined) this.fetchConnections();
          break;
        case "circles":
          if (!this.circles || this.circles.length === 0) this.fetchCircles();
          break;
        case "channels":
          if (!this.channels || this.channels.length === 0)
            this.fetchChannels();
          break;
        default:
          break;
      }
    }
  }

  render() {
    const user = app?.session?.user;
    const slug = user?.slug || user?.id;
    return html`<section class="card">
      ${this.renderSearchBox()}

      <accordion-list class="network-items">
        <details
          class="green suggested"
          ?open=${this.section === "network"}
          data-type="network"
          @toggle=${this.#toggle.bind(this)}
        >
          <summary>Expand your network</summary>
          ${this.renderSuggested()}
        </details>

        <details
          class="red connections"
          ?open=${this.section === "connections"}
          @toggle=${this.#toggle.bind(this)}
          data-type="connections"
          ${ref(this.#connectionsRef)}
        >
          <summary>
            Connections
            ${this.connectionsPagination
              ? html`(${this.connectionsPagination.total})
                  <a
                    class="button small"
                    href="/profile/${slug}/followers#connections"
                  >
                    Show more
                  </a>`
              : nothing}
          </summary>
          ${this.renderConnections()}
        </details>

        <details
          class="yellow circles"
          ?open=${this.section === "circles"}
          @toggle=${this.#toggle.bind(this)}
          data-type="circles"
        >
          <summary>
            Circles ${this.circles ? html`(${this.circles.length})` : nothing}
          </summary>

          ${this.renderCircles()}
        </details>

        <details
          class="blue channels"
          ?open=${this.section === "channels"}
          @toggle=${this.#toggle.bind(this)}
          data-type="channels"
          ${ref(this.#channelsRef)}
        >
          <summary>
            Channels
            ${this.channelsPagination
              ? html`(${this.channelsPagination.total})
                  <a
                    class="button small"
                    href="/network/channels"
                  >
                    Show more
                  </a>`
              : nothing}
          </summary>
          ${this.renderChannels()}
        </details>
      </accordion-list>
    </section>`;
  }

  renderCircles() {
    if (!this.circles) {
      return html`
        <flex-container breakpoint="tiny">
          <flex-item class="col-6">
            <app-shimmer style="height: 240px;"></app-shimmer>
          </flex-item>
          <flex-item class="col-6">
            <section class="card">
              <circle-link class="grey">
                <a href="/network/circles/new">
                  <svg-icon icon="plus"></svg-icon>
                </a>
              </circle-link>
            </section>
          </flex-item>
        </flex-container>
      `;
    }
    return html`
      <flex-container breakpoint="tiny">
        ${repeat(this.circles, (circle, idx) => {
          const colors = ["red", "yellow", "green", "blue"];
          const color = colors[idx % colors.length];
          return html`
            <flex-item class="col-6">
              <section class="card">
                <circle-link class="${color}">
                  <a href="/network/circles/${circle.id}">
                    <h3>${circle.name}</h3>
                  </a>
                </circle-link>
              </section>
            </flex-item>
          `;
        })}

        <flex-item class="col-6">
          <section class="card">
            <circle-link class="grey">
              <a href="/network/circles/new">
                <svg-icon icon="plus"></svg-icon>
              </a>
            </circle-link>
          </section>
        </flex-item>
      </flex-container>
    `;
  }

  renderChannels() {
    if (!this.channels) {
      return html`
        <flex-container breakpoint="tiny">
          <flex-item class="col-6">
            <app-shimmer style="height: 100px;"></app-shimmer>
          </flex-item>
          <flex-item class="col-6">
            <app-shimmer style="height: 100px;"></app-shimmer>
          </flex-item>
        </flex-container>
      `;
    }
    return html`
      <div class="network-grid">
        ${repeat(this.channels, (channel) => {
          const { is_following } = channel;

          return html`
            <channel-link>
              <svg-icon icon="channels"></svg-icon>
              <a href="/network/channels/${channel.slug ?? channel.id}">
                <h4>${channel.name}</h4>
              </a>
              <button
                type="button"
                class="button tiny outline align-content-end"
                @click=${() => this.#channelFollowToggle(channel)}
              >
                ${is_following ? "Unfollow" : "Follow"}
              </button>
            </channel-link>
          `;
        })}
      </div>
    `;
  }

  #formAction(e) {
    if (
      e.detail.submitter &&
      typeof e.detail.submitter.dataset?.filter === "string"
    ) {
      this.type = e.detail.submitter.dataset.filter;
      e.detail.submitter.blur();
    }
    this.#getUsers();
  }

  async #followUser(e) {
    const userId = e.target.closest("single-connection").dataset.id;
    const source = e.target.closest("single-connection").dataset.source;
    await this.#domain.followUser(userId);
    if (source === "suggested") {
      await this.fetchSuggested();
    } else {
      await this.fetchConnections();
    }
    app.addToastMessage("User followed");
  }

  async #unfollowUser(e) {
    const userId = e.target.closest("single-connection").dataset.id;
    const source = e.target.closest("single-connection").dataset.source;
    await this.#domain.unfollowUser(userId);
    if (source === "suggested") {
      await this.fetchSuggested();
    } else {
      await this.fetchConnections();
    }
    app.addToastMessage("User unfollowed");
  }

  async #channelFollowToggle(channel) {
    const task = async () => {
      const { id, is_following } = channel;
      if (is_following) {
        await this.#channelDomain.unfollow(id);
      } else {
        await this.#channelDomain.follow(id);
      }

      await this.fetchChannels();
    };

    await Task.run(task, {
      ghost: this.#channelsRef.value,
    });
  }

  async #getUsers() {
    const task = async () => {
      const result = await this.#domain.searchUsers({
        filter: this.filter,
        type: this.type,
      });

      this.users = result.data;
      this.pagination = result.pagination;
    };

    await Task.run(task, {
      description: "User fetcher",
    });
  }

  #prevChannelPage() {
    this.channelsPage--;
    this.#reloadChannels();
  }
  #nextChannelPage() {
    this.channelsPage++;
    this.#reloadChannels();
  }
  #reloadChannels() {
    return Task.run(
      async () => {
        await this.fetchChannels();
      },
      {
        ghost: this.#channelsRef.value,
      }
    );
  }

  renderConnections() {
    if (this.connections === undefined)
      return html`
        <div style="display: flex; gap: 15px;">
          <app-shimmer style="width: 33%; height: 200px;"></app-shimmer>
          <app-shimmer style="width: 33%; height: 200px;"></app-shimmer>
          <app-shimmer style="width: 33%; height: 200px;"></app-shimmer>
        </div>
        <div style="display: flex; gap: 15px;">
          <app-shimmer style="width: 33%; height: 200px;"></app-shimmer>
          <app-shimmer style="width: 33%; height: 200px;"></app-shimmer>
          <app-shimmer style="width: 33%; height: 200px;"></app-shimmer>
        </div>
      `;

    return html`
      <connections-list>
        ${repeat(this.connections, (u) =>
          this.renderSingleConnection(u, "connections")
        )}
      </connections-list>
    `;
  }

  renderSuggested() {
    return html`
      <suggested-contacts>
        ${this.#suggestionsTemplate()} ${this.#expertsTemplate()}
      </suggested-contacts>
    `;
  }

  #suggestionsTemplate() {
    if (this.suggestions === undefined) {
      return html`
        <h4>Suggested users</h4>
        <div style="display: flex; gap: 10px;">
          <app-shimmer style="width: 18%; height: 150px;"></app-shimmer>
          <app-shimmer style="width: 18%; height: 150px;"></app-shimmer>
          <app-shimmer style="width: 18%; height: 150px;"></app-shimmer>
          <app-shimmer style="width: 18%; height: 150px;"></app-shimmer>
          <app-shimmer style="width: 18%; height: 150px;"></app-shimmer>
        </div>
      `;
    }

    if (Array.isArray(this.suggestions) && this.suggestions.length === 0) {
      return html`
        <h4>Suggested users</h4>
        <i>There are no suggested users at this moment.</i>
      `;
    }

    return html`
      <h4>Suggested users</h4>
      <connections-list class="mb-small">
        ${repeat(this.suggestions, (u) =>
          this.renderSingleConnection(u, "suggested")
        )}
      </connections-list>
    `;
  }

  #expertsTemplate() {
    if (this.experts === undefined) {
      return html`
        <h4>Experts</h4>
        <div style="display: flex; gap: 10px;">
          <app-shimmer style="width: 18%; height: 150px;"></app-shimmer>
          <app-shimmer style="width: 18%; height: 150px;"></app-shimmer>
          <app-shimmer style="width: 18%; height: 150px;"></app-shimmer>
          <app-shimmer style="width: 18%; height: 150px;"></app-shimmer>
          <app-shimmer style="width: 18%; height: 150px;"></app-shimmer>
        </div>
      `;
    }

    if (Array.isArray(this.experts) && this.experts.length === 0)
      return nothing;

    return html`
      <h4>Experts</h4>
      <connections-list>
        ${repeat(this.experts, (u) =>
          this.renderSingleConnection(u, "suggested")
        )}
      </connections-list>
    `;
  }

  renderSingleConnection(u, source) {
    return html`
      <single-connection data-id="${u.id}" data-source="${source}">
        <a href="/profile/${u.slug || u.id}">
          <figure>
            <img
              src="${u.profile_img_url ?? "/assets/img/profile-picture.webp"}"
              onerror="this.src = '/assets/img/profile-picture.webp'"
              alt="User profile picture"
              loading="lazy"
            />
          </figure>
        </a>

        <div class="info">
          <a href="/profile/${u.slug ?? u.id}">
            <h4>${u.firstname} ${u.lastname}</h4>
            ${u.badges
              ? html`
                  ${repeat(
                    u.badges,
                    (b) => html`
                      <badge-tag class="small blue filled">
                        <svg-icon icon="crown"></svg-icon> ${b.name}
                      </badge-tag>
                    `
                  )}
                `
              : nothing}
          </a>

          <div class="group">
            <div class="related">
              <svg-icon size="16px" icon="link"></svg-icon>
              <span class="count"> ${u.followers_count} </span>
            </div>

            <div class="connections">
              <svg-icon size="16px" icon="account-add"></svg-icon>
              <span class="count"> ${u.following_count} </span>
            </div>
          </div>
        </div>

        <div class="controls">
          ${u.has_connection
            ? html`
                <button class="green outline tiny" type="button">
                  Connected
                </button>
                <button
                  class="blue outline tiny"
                  type="button"
                  @click=${this.#unfollowUser}
                  title="Click to unfollow"
                >
                  Following
                </button>
              `
            : nothing}
          ${!u.has_connection && u.following
            ? html`
                <button class="beige tiny" type="button" disabled>
                  <svg-icon size="12px" icon="minus"></svg-icon>
                  Pending
                </button>
                <button
                  class="blue outline tiny"
                  type="button"
                  @click=${this.#unfollowUser}
                  title="Click to unfollow"
                >
                  Following
                </button>
              `
            : nothing}
          ${!u.has_connection && !u.following
            ? html`
                <button
                  class="beige tiny"
                  type="button"
                  @click=${this.#followUser}
                >
                  <svg-icon size="16px" icon="plus"></svg-icon>
                  Follow
                </button>
              `
            : nothing}
        </div>
      </single-connection>
    `;
  }

  renderSearchBox() {
    return html`
      <connection-search>
        <x-form @action=${this.#formAction} live>
          <form>
            <label>
              <span data-label>Search &amp; connect</span>
              <input
                type="search"
                @focus=${(e) => {
                  AutoComplete.connect(e, this.connectionsAutoComplete);
                  // e.target._autoComplete.clear = ()=>{}
                }}
                placeholder="Start typing to search..."
                name="filter"
                value="${this.filter}"
              />
            </label>
          </form>
        </x-form>
      </connection-search>
    `;
  }

  get connectionsAutoComplete() {
    return {
      hideCategory: true,
      //debug: true,
      categories: {
        Users: {
          trigger: (options) => {
            return options.search.length > 2;
          },

          action: (options) => {
            if (options.invite) {
              location.href = `/benefits/share?${new URLSearchParams({
                invite: btoa(options.inviteData),
              })}`;
            } else if (options?.user?.id) {
              window.location.replace(
                `/profile/${options.user.slug ?? options.user.id}`
              );
            }
          },

          getItems: async (options) => {
            const request = await this.#domain.searchUsers({
              query: {
                filter: options.search,
                per_page: 25,
              },
              abortPrevious: true,
            });

            const data = request.data;

            if (data.length === 0) {
              const names = detectPersonNames(options.search);

              if (names.length) {
                return [
                  {
                    icon: "diamond",
                    inviteData: names[0],
                    text: `Invite ${names[0]} to Qogni?`,
                    description: "Accepted invites get you 40 points!",
                    category: "Invite",
                    invite: true,
                  },
                ];
              }
            }

            return data.map((i) => {
              let description = `
                  ${i.has_connection ? `<strong>Connected</strong>, ` : ``}
                  ${i.followers_count ? `${i.followers_count} followers, ` : ``}
                `
                .trim()
                .replace("\n", "");
              if (description.substring(description.length - 1) === ",")
                description = description.substring(0, description.length - 1);
              return {
                text: `${i.firstname} ${i.lastname}`,
                description,
                image: i.profile_img_url ?? "/assets/img/profile-picture.webp",
                user: i,
              };
            });
          },
        },
      },
    };
  }
}
