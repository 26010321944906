/**
 * Calls the given callback when the ESC key is pressed, and when
 * the user clicks outside the given element.
 * @param {HTMLElement} element
 * @param {Function} callback
 */
export function autoEscape(element, callback) {
  window.addEventListener("keydown", (e) => {
    if (e.key === "Escape") callback();
  });
  document.addEventListener("mousedown", (e) => {
    if (!element.contains(e.target)) callback();
  });
}

export function detectPersonNames(text) {
  if (text.length < 5 || text.trim().indexOf(" ") === -1) return false;

  const regex = /\b\w{2,}\s+\w{2,}\b/gi;

  let match;
  const names = [];

  // Find matches
  while ((match = regex.exec(text)) !== null) {
    names.push(match[0]);
  }

  return names;
}

export const getNeurotransmitterColor = (name) => {
  const neurotransmitterColors = {
    acetylcholine: "var(--color-accent-100)",
    dopamine: "var(--color-accent-200)",
    serotonin: "var(--color-accent-300)",
    gaba: "var(--color-accent-400)",
  };

  return neurotransmitterColors[name] ?? "var(--color-primary-300)";
}

export function isIterable(obj) {
  return typeof obj[Symbol.iterator] === 'function';
}

export const nativeWebShare = async ({title, text}) => {
  const shareData = {
    ...(title && {title}),
    text
  };

  if (!navigator.canShare) {
    try {
      await navigator.clipboard.writeText(text);
      app.addToastMessage("Successfully copied to clipboard");
      console.info("Successfully copied to clipboard");
    } catch (err) {
      console.error("Failed to copy content: ", err);
    }
  } else if (navigator.canShare(shareData)) {
    try {
      await navigator.share(shareData);
      console.info("Shared successfully");
    } catch (err) {
      console.error("Error sharing", err);
    }
  } else {
    console.warn("Specified data cannot be shared.", shareData);
  }
}


export function openCenteredWindow(url, width, height) {
  const left = (window.screen.width / 2) - (width / 2);
  const top = (window.screen.height / 2) - (height / 2);
  return window.open(url, '', `toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=yes, resizable=yes, width=${width}, height=${height}, top=${top}, left=${left}`);
}

/**
 * Truncate string at given length-3 and add '...' when truncated
 * @param {String} str 
 * @param {Number} maxLength 
 * @returns {String}
 */
export function truncateString(str, maxLength) {
  return str.length > maxLength ? `${str.slice(0, maxLength - 3)}...` : str;
}


export function playSound(src) {
  let audioCtx;
  try {
    audioCtx = new AudioContext();
  } catch (e) {
    console.error("Web Audio API is not supported in this browser");
  }

  // Load the sound file
  fetch(src)
    .then((response) => response.arrayBuffer())
    .then((arrayBuffer) => audioCtx.decodeAudioData(arrayBuffer))
    .then((audioBuffer) => {
      playAudio(audioCtx, audioBuffer);
    });

  // Function to play the sound
  function playAudio(audioContext, buffer) {
    const source = audioContext.createBufferSource();
    source.buffer = buffer;
    source.connect(audioContext.destination);
    source.start(0); // Play the sound immediately
  }
}