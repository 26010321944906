import { Task } from "@qogni-technologies/pwa-utils-library/src/utils/task";
import { html, nothing } from "lit";
import { createRef, ref } from "lit/directives/ref.js";
import { repeat } from "lit/directives/repeat.js";
import { AccountDomain } from "../../domain/account-domain";
import {
  AuthenticatedMixin,
  OnboardedMixin,
  PWAPage,
} from "../../shared/pwa-page";

export class PageSavedItems extends OnboardedMixin(
  AuthenticatedMixin(PWAPage)
) {
  #accountDomain;
  #currentPage = 1;
  #lastPage;
  #infinityListElement = createRef();

  static get properties() {
    return {
      list: { type: Array },
    };
  }

  constructor() {
    super();
    this.#accountDomain = new AccountDomain();
  }

  async connectedCallback() {
    await super.connectedCallback();

    await this.#fetch();
    this.#infinityListElement.value.addItems(this.list);
  }

  async #fetch() {
    const task = async () => {
      let listId;

      if (app.cache.getValue("saved_list")) {
        listId = app.cache.getValue("saved_list")[0].id;
      } else {
        const res = await this.#accountDomain.getSavedLists();
        app.cache.setValue("saved_list", res.data);
        listId = res.data[0].id;
      }

      const res = await this.#accountDomain.getSavedListItems(listId, {
        page: this.#currentPage,
      });
      this.list = res.data;
      this.#currentPage = res?.pagination?.current_page;
      this.#lastPage = res?.pagination?.last_page;
    };

    await Task.run(task);
  }

  render() {
    return html`
      <section class="hero center">
        <h1>Saved posts in Default list</h1>
      </section>
      ${this.#renderList()}
    `;
  }

  #renderList() {
    if (!this.list) {
      const shimmerList = Array.from({ length: 5 }, (_, index) => index);
      return html`
        ${repeat(shimmerList, () => {
          return html`
            <section class="card">
              <app-shimmer></app-shimmer>
              <app-shimmer></app-shimmer>
              <app-shimmer class="tiny"></app-shimmer>
            </section>
          `;
        })}
      `;
    }

    if (this.list.length === 0) {
      return html`
        <div class="empty-state">
          <h2>No Saved Items Yet</h2>
          <p>Start saving your favorite items by clicking the save menu button from recipe, workout, and post.</p>
        </div>
      `
    }

    return html`
      <infinite-list
        ${ref(this.#infinityListElement)}
        .renderItem=${this.#renderItem.bind(this)}
        @scroll-end=${this.#onScrollEnd.bind(this)}
      ></infinite-list>
    `;
  }

  #renderItem(item) {
    if (item.post_id) {
      return html`
        <post-entry
          unsave
          .postId=${item.post_id}
          .stats=${item.post?.stats}
          .post=${item.post}
          .saveId=${item.id}
          .user=${item.post.user}
          @refresh=${this.#refreshList.bind(this)}
        ></post-entry>
      `;
    }

    if (item.option_id) {
      return html`
        <post-entry
          unsave
          .option=${item.option}
          .saveId=${item.id}
          @refresh=${this.#refreshList.bind(this)}
        ></post-entry>
      `;
    }

    if (item.recipe_id) {
      return html`
        <recipe-card
          unsave
          .recipe=${item.recipe}
          .saveId=${item.id}
          @refresh=${this.#refreshList.bind(this)}
        ></recipe-card>
      `;
    }

    if (item.workout_id) {
      return html`<workout-card
        unsave
        .workout=${item.workout}
        .saveId=${item.id}
        @refresh=${this.#refreshList.bind(this)}
      ></workout-card>`;
    }

    return nothing;
  }

  async #onScrollEnd() {
    this.#currentPage++;
    if (this.#currentPage === 1 || this.#currentPage <= this.#lastPage) {
      await this.#fetch();
      this.#infinityListElement.value.addItems(this.list);
    }
  }

  async #refreshList() {
    this.#currentPage = 1;
    await this.#fetch();
    this.#infinityListElement.value.addItems(this.list, true);
  }
}
