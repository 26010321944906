import {AccountDomain} from "../domain/account-domain";
import {createProxy} from "@qogni-technologies/design-system/src/shared/common";

export const isSupported = () =>
  'Notification' in window &&
  'serviceWorker' in navigator &&
  'PushManager' in window;

/**
 * Storage of session and verification of session in-memory.
 * @event received User is logged out, can be triggered due to JWT-session being expired or by action of logout (will be defined in event_source).
 * @event permission-granted User profile is updated.
 * @event permission-denied Authenticated
 * @author Tom Valk
 */
export class NotificationCenter extends EventTarget {
  #app;
  #accountDomain;
  #pollingInterval;
  #unreadStats = null;

  /**
   *
   * @param {QogniApp} app
   */
  constructor(app) {
    super();
    this.#app = app;
    this.#accountDomain = new AccountDomain();
    this.#unreadStats = createProxy(this);
    // Trigger set of default poll interval (and setting interval itself).
    this.pollInterval = 10000;

    this.#app.session.addEventListener('profile-updated', (e) => {
      this.pollAction();
      if (!this.hasPermission && (
        e.detail.user?.notifications_enabled === 1
        || e.detail.user?.notifications_enabled === null
      )) {
        this.randomNotificationRequest(e.detail.user?.notifications_enabled === null, false);
      }
    });
    this.addEventListener('statechange', this.#unreadStatsChange.bind(this));
  }

  set pollInterval(interval) {
    clearInterval(this.#pollingInterval);
    this.#pollingInterval = setInterval(() => {
      this.pollAction();
    }, interval);
  }

  get unreadStats() {
    return this.#unreadStats;
  }

  /**
   * @param {QogniApp} app
   * @returns {NotificationCenter}
   */
  static factory(app) {
    return new NotificationCenter(app);
  }

  get hasPermission() {
    if (!isSupported()) return false;
    return Notification.permission === 'granted';
  }

  get blockedNotifications() {
    if (!isSupported()) return false;
    return Notification.permission === 'denied';
  }

  async askPermission(sendWelcomeMessage = true) {
    if (!isSupported()) return false;
    const permission = await Notification.requestPermission();
    if (permission === 'granted' && sendWelcomeMessage) {
      // Send notification to confirm change.
      this.localNotification('Notifications are now enabled', {
        body: 'We will keep you up-to-date with your progress!',
        icon: '/assets/img/ico-512.png'
      });
    }
    return permission;
  }

  localNotification(message, options = {}) {
    if (!isSupported() || !this.hasPermission) return false;
    return new Notification(message, options);
  }

  /**
   * Ask randomly for notifications to be enabled. Is called mostly internally.
   *
   * @param firstRequest
   * @param sendWelcomeMessage
   * @returns {Promise<boolean>}
   */
  async randomNotificationRequest(firstRequest = false, sendWelcomeMessage = true) {
    if (!isSupported()) return false;
    const permission = this.askPermission(sendWelcomeMessage);
    if (permission === 'granted' && !firstRequest) {
      // Only now update the settings of the profile.
      await this.#accountDomain.updateProfile({
        notifications_enabled: true
      });
    }
  }

  /**
   * Trigger poll action. Normally called with setInterval set on the instance.
   *
   * @returns {Promise<void>}
   */
  async pollAction() {
    try {
      if (!app.session?.user) return; // ignore when user not authenticated.
      const result = await this.#accountDomain.poll();
      if (this.#unreadStats.unread_messages !== result.unread_messages) this.#unreadStats.unread_messages = result.unread_messages;
      if (this.#unreadStats.unread_notifications !== result.unread_notifications) this.#unreadStats.unread_notifications = result.unread_notifications;
    } catch {
      // ignore.
    }
  }

  async #unreadStatsChange(e) {
    if (e.detail.oldValue === undefined) return;
    if (e.detail.target.unread_messages > 0 && e.detail.oldValue < e.detail.target.unread_messages) {
      // this.localNotification(`You received a new message!`);
    }
  }
}
