import {AppDomainHandler} from "../shared/app-domain-handler";
import {ApiRequest} from "../shared/APIRequest";
import {base64toBlob} from "@qogni-technologies/design-system/src/shared/common";
import { showAlert } from "@qogni-technologies/design-system/src/components/base/modal-dialog";

export const Languages = [
  { label: 'English', value: 'en-US' },
  { label: 'Nederlands', value: 'nl-NL' }
]
export const FoodPreferences = [
  { name: 'Vegan', key: 'vegan', category: 'Preferences' },
  { name: 'Vegetarian', key: 'vegetarian', category: 'Preferences' },
  { name: 'Pescetarian', key: 'pescetarian', category: 'Preferences' },

  { key: "gluten_intolerance", name: "Gluten Intolerance" , category: 'Allergies' },
  { key: "celiac_disease", name: "Celiac Disease" , category: 'Allergies' },
  { key: "nut_allergy", name: "Nut Allergy" , category: 'Allergies' },
  { key: "shellfish_allergy", name: "Shellfish Allergy" , category: 'Allergies' },
  { key: "fish_allergy", name: "Fish Allergy" , category: 'Allergies' },
  { key: "soy_intollerance", name: "Soy Intollerance" , category: 'Allergies' },
  { key: "egg_allergy", name: "Egg Allergy" , category: 'Allergies' },
  { key: "lactose", name: "Lactose Intolerance" , category: 'Allergies' },
]

export class AccountDomain extends AppDomainHandler {
  #api;

  constructor() {
    super();
    this.#api = ApiRequest.factory();
  }

  async getJobList(lang = 'en-US') {
    let langCode = 'en';
    if (lang === 'nl-NL') langCode = 'nl';
    const response = await fetch(`/assets/data/${langCode}/jobs.json`);
    return await response.json();
  }

  /**
   * Generate email request code by given email.
   *
   * @param email
   * @returns {Promise<string|false>}
   */
  async updateEmailRequest(email) {
    let result;
    try {
      result = await this.#api.postData('/users/me/email/request-code', {
        new_email_address: email
      });
    } catch (err) {
      if (err.response && err.response.status === 409) {
        throw new Error('The email address given is already in use');
      }
      throw err;
    }
    if (!result.status) {
      return false;
    }

    return result.data.change_jwt;
  }

  async getEmails() {
    const result = await this.#api.getData('/users/me/emails');

    if (!result.status) return false;
    return result.data;
  }

  /**
   * Updates a user email in the application
   *
   * @param {String} email
   * @param {Object} options - { primary: boolean, work: boolean }
   */
  async updateEmail(email, options) {
    let result;

    try {
      result = await this.#api.putData(`/users/me/emails/${email}`, {
        primaty: false,
        work: false,
        ...(options && { ...options })
      })
    } catch(err) {
      switch(err.response && err.response.status) {
        case 401:
          app.addToastMessage("User not authenticated", { type: "error" });
          return false;
        case 409:
          app.addToastMessage("Entered Email already verified", { type: "error" });
          return false;
        default:
          break;
      }
    }

    if(!result.status) return false;
    return result;
  }

  async emailVerificationRequestCode(email) {
    let result;
    try {
      result = await this.#api.postData(`/users/me/emails/${email}/verification/request-code`);
    } catch (err) {
      switch(err.response && err.response.status) {
        case 401:
          app.addToastMessage("User not authenticated", { type: "error" });
          return false;
        case 409:
          app.addToastMessage("Entered Email already verified", { type: "error" });
          return false;
        default:
          break;
      }
    }

    if (!result.status) return false;
    return result.data.verify_jwt;
  }

  async emailVerificationComplete(email, verifyJwt, pin) {
    let result;
    try {
      result = await this.#api.postData(`/users/me/emails/${email}/verification/complete`, {
        pin,
        verify_jwt: verifyJwt
      });
    } catch (err) {
      switch(err.response && err.response.status) {
        case 400:
          app.addToastMessage("Validation errors.", { type: "error" });
          return false;
        case 401:
          app.addToastMessage("User not authenticated", { type: "error" });
          return false;
        case 403:
          app.addToastMessage("Entered pin is incorrect or expired!", { type: "error" });
          return false;
        default:
          break;
      }
    }

    if (!result.status) return false;
    return result.status;
  }

  async addNewEmail(email) {
    let result;

    try {
      result = await this.#api.postData('/users/me/emails', {
        email: email
      });
    } catch(err) {
      switch(err.response && err.response.status) {
        case 400:
          app.addToastMessage("Validation errors.", { type: "error" });
          return false;
        case 403:
          app.addToastMessage("We allow a maximum of 10 email addresses per account", { type: "error" });
          return false;
        case 409:
          await showAlert({
            title: "Alert!",
            message: "The email address given is already in use",
          });
          return false;
        default:
          break;
      }
    }

    if (!result.status) return false;

    app.addToastMessage("New Email added");
    return result.data;
  }

  async deleteEmail(email) {
    let result
    try {
      result = await this.#api.deleteData(`/users/me/emails/${email}`);
    } catch (err) {
      switch(err.response && err.response.status) {
        case 404:
          app.addToastMessage("Emails not found", { type: "error" });
          return false;
        case 409:
          app.addToastMessage("Cannot remove primary email", { type: "error" });
          return false;
        default:
          break;
      }
    }

    if (!result.status) return false;
    return result.status;
  }

  /**
   * Ask for confirmation code to be sent in order to delete the account.
   *
   * @returns {Promise<string|false>}
   */
  async deleteRequest() {
    const result = await this.#api.postData('/users/me/delete/request-code');
    if (!result.status) {
      return false;
    }

    return result.data.delete_jwt;
  }

  /**
   * Confirm account deletion.
   *
   * @returns {Promise<boolean>}
   * @param deleteJwt
   * @param pin
   */
  async deleteConfirm(deleteJwt, pin, reason, reasonExtra) {
    let result;

    try {
      result = await this.#api.postData('/users/me/delete/confirm', {
        delete_jwt: deleteJwt,
        pin,
        reason,
        reason_extra: reasonExtra
      });
    } catch (err) {
      switch(err.response && err.response.status) {
        case 400:
          app.addToastMessage("Validation errors.", { type: "error" });
          return false;
        case 401:
          app.addToastMessage("User not authenticated", { type: "error" });
          return false;
        case 403:
          app.addToastMessage("Entered PIN is incorrect or expired!", { type: "error" });
          return false;
        default:
          break;
      }
    }
    
    if (!result.status) return false;
    return result;
  }

  /**
   * Confirm email change, and process at server-side.
   *
   * @returns {Promise<boolean>}
   * @param changeJwt
   * @param pin
   */
  async updateEmailConfirm(changeJwt, pin) {
    let result;
    try {
      result = await this.#api.postData('/users/me/email/confirm', {
        change_jwt: changeJwt,
        pin
      });
    } catch (err) {
      if (err.response && err.response.status === 409) {
        throw new Error('The email address given is already in use');
      }
      throw err;
    }
    return result.status;
  }

  /**
   * Update profile fields.
   * @param details
   * @returns {Promise<*>}
   */
  async updateProfile(details) {
    const result = await this.#api.putData('/users/me', {
      ...details
    });
    return result.status
  }

  async uploadProfileImage(imageBase64, contentType) {
    const formData = new FormData();
    formData.append('file', base64toBlob(imageBase64, contentType), 'profile-picture.jpg');
    await this.#api.uploadFile('POST', '/users/me/picture', formData);
  }

  /**
   * Delete current profile picture.
   * @returns {Promise<*>}
   */
  async deleteProfilePicture() {
    return this.updateProfile({
      profile_img: null
    });
  }

  /**
   * Get all possible topics.
   *
   * @returns {Promise<*|string>}
   */
  async getAllPossibleTopics() {
    return await this.#api.getData('/interest_topics');
  }

  async getConnections(options = {}) {
    return await this.#api.getData(`/users/me/connections?${new URLSearchParams(options ?? {})}`);
  }

  async followUser(userId) {
    return await this.#api.postData(`/users/${userId}/follow`);
  }

  async unfollowUser(userId) {
    return await this.#api.postData(`/users/${userId}/unfollow`);
  }

  async searchUsers(options = {}) {
    return await this.#api.getData(`/users?${new URLSearchParams(options?.query ?? {})}`, options);
  }

  async getUser(selector, options = {}) {
    return await this.#api.getData(`/users/${selector}?${new URLSearchParams(options?.query ?? {})}`, options);
  }

  async poll() {
    return await this.#api.getData(`/users/me/polling`, {abortPrevious: true});
  }

  async createInvitation(source) {
    const response = await this.#api.postData(`/invitations`, {source});
    return response.data.id;
  }

  async createAdvancedInvitation(data) {
    const response = await this.#api.postData(`/invitations`, data);
    return response.data.id;
  }

  async suggestedUsers() {
    const response = await this.#api.getData(`/users/me/connections/suggested`, {});
    return {
      suggestions: this.#mergeArraysToMax(response.data.suggested, response.data.random, 3),
      experts: response.data.experts,
    }
  }

  #mergeArraysToMax(arr1, arr2, max = 3) {
    const result = [];
    const length1 = arr1.length;
    const length2 = arr2.length;

    // Ensure we have at least one item from the second array
    let itemsFromArr2 = Math.min(length2, 1);
    let itemsFromArr1 = Math.min(length1, max - itemsFromArr2);

    // Adjust if the first array has fewer items than needed
    if (itemsFromArr1 + itemsFromArr2 < max && itemsFromArr2 < length2) {
      itemsFromArr2 = Math.min(length2, max - itemsFromArr1);
    }

    // Add elements from the first array
    for (let i = 0; i < itemsFromArr1; i++) {
      result.push(arr1[i]);
    }

    // Add elements from the second array
    for (let i = 0; i < itemsFromArr2; i++) {
      result.push(arr2[i]);
    }

    return result;
  }

  async getFollowing(page = 1) {
    return await this.#api.getData("/users/me/following", {
      per_page: 25,
      page,
    });
  }

  async getFollowers(page = 1) {
    return await this.#api.getData("/users/me/followers", {
      per_page: 25,
      page,
    });
  }

  async getSavedLists() {
    return await this.#api.getData("/users/me/saved_lists");
  }

  async saveItemToSavedList(options) {
    let result;
    let listId;

    try {
      if (app.cache.getValue('saved_list')) {
        listId = app.cache.getValue('saved_list')[0].id;
      } else {
        const res = await this.getSavedLists();
        app.cache.setValue('saved_list', res.data);
        listId = res.data[0].id;
      }
      result = await this.#api.postData(`/users/me/saved_lists/${listId}/saved_items`, options);
    } catch (err) {
      switch(err.response && err.response.status) {
        case 400:
          app.addToastMessage("Validation errors.", { type: "error" });
          return false;
        case 404:
          app.addToastMessage("List not found", { type: "error" });
          return false;
        case 409:
          app.addToastMessage("Item already saved before", { type: "error" });
          return false;
        default:
          break;
      }
    }

    if (!result.status) return false;
    return result;
  }

  async unsaveItemToSavedList(itemId) {
    let result;
    let listId;

    try {
      if (app.cache.getValue("saved_list")) {
        listId = app.cache.getValue("saved_list")[0].id;
      } else {
        const res = await this.getSavedLists();
        app.cache.setValue("saved_list", res.data);
        listId = res.data[0].id;
      }
      result = await this.#api.deleteData(
        `/users/me/saved_lists/${listId}/saved_items/${itemId}`
      );
    } catch (err) {
      switch (err.response && err.response.status) {
        case 404:
          app.addToastMessage("Entity not found", { type: "error" });
          return false;
        default:
          break;
      }
    }

    if (!result.status) return false;
    return result;
  }

  async getSavedListItems(listId, options = {}) {
    const query = {
      page: 1,
      per_page: 15,
      ...options,
    };

    return await this.#api.getData(
      `/users/me/saved_lists/${listId}/saved_items?${new URLSearchParams(
        query
      )}`
    );
  }
}
