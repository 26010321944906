import "../settings";
import "../notifications";
import "../messages/messages";

import { html, nothing } from "lit";
import {
  AuthenticatedMixin,
  OnboardedMixin,
  WidgetEnabledPWAPage,
} from "../../shared/pwa-page";
import { AccountDomain, FoodPreferences } from "../../domain/account-domain";
import { throttle } from "@qogni-technologies/design-system/src/shared/common";

import './manage-emails';
import { resizeImage } from "@qogni-technologies/pwa-utils-library/src/utils/resize-image";
import { Task } from "@qogni-technologies/pwa-utils-library/src/utils/task";

export class PageAccount extends OnboardedMixin(
  AuthenticatedMixin(WidgetEnabledPWAPage)
) {
  #domain;
  #topics = [];

  static get properties() {
    return {
      deleteVisible: { type: Boolean },
      deleteJwt: { type: String },
    };
  }

  constructor() {
    super();
    this.#domain = new AccountDomain();
    this.deleteVisible = false;
  }

  get allergiesAutoComplete() {
    return {
      hideCategory: true,
      cache: false,
      categories: {
        Preferences: {
          getItems: () => {
            return FoodPreferences.filter(
              (p) => p.category === "Preferences"
            ).map((p) => {
              return {
                text: p.name,
              };
            });
          },
        },
        Allergy: {
          getItems: () => {
            return FoodPreferences.filter(
              (p) => p.category === "Allergies"
            ).map((p) => {
              return {
                text: p.name,
              };
            });
          },
        },
      },
    };
  }

  get goalsAutoComplete() {
    return {
      categories: {
        Goals: {
          getItems: () => {
            return this.#topics.map((t) => {
              return {
                text: t.name,
              };
            });
          },
        },
      },
    };
  }

  async #handleUploadImage(e) {
    if (!e.target.files || e.target.files.length !== 1) return;

    const task = async () => {
      const imageFile = e.target.files[0];
      const resizedImage = await resizeImage(imageFile, {
        maxWidth: 500,
        maxHeight: 500,
        contentType: "image/jpeg",
        quality: 7.5,
      });

      try {
        await this.#domain.uploadProfileImage(
          resizedImage.url,
          resizedImage.contentType
        );
        app.addToastMessage("Profile picture updated!");
      } catch (err) {
        if (!err.errorData) {
          return app.addToastMessage(
            "Unknown error when uploading new image, try again later or with a different picture.",
            { type: "error" }
          );
        }
        app.addToastMessage(err.errorData.errors.file.join(","), {
          type: "error",
        });
      }

      await app.session.refreshUser(true);
      app.topBar.requestUpdate();
    };

    return Task.run(task, {
      ghost: e.target.closest(".profile-picture"),
      description: "Updating profile image",
    });
  }

  async #deleteProfilePicture(e) {
    const task = async () => {
      await this.#domain.deleteProfilePicture();
      await app.session.refreshUser();
      app.topBar.requestUpdate();
    };

    return Task.run(task, {
      ghost: e.target.closest(".profile-picture"),
      description: "Updating profile image",
    });
  }

  #updateFoodPreferences(e) {
    const keyObj = {};
    for (const pref of FoodPreferences) {
      keyObj[pref.key] =
        e.detail.value.filter((p) => p === pref.name).length > 0;
    }
    return keyObj;
  }
  #updateGoalsPreferences(e) {
    const keyObj = { interest_topics: [] };
    for (const goal of e.detail.value) {
      const goalFiltered = this.#topics.filter((g) => g.name === goal);
      if (goalFiltered.length !== 1) continue;
      keyObj["interest_topics"].push(goalFiltered[0].id);
    }
    return keyObj;
  }

  #foodPreferencesValue() {
    const prefs = [];
    for (const pref of FoodPreferences) {
      if (app.session.user[pref.key]) prefs.push(pref.name);
    }
    return prefs;
  }

  #goalsValue() {
    return app.session?.user?.interest_topics?.map((t) => t.name);
  }

  /**
   * On-the-fly part of the form, change actions.
   * @param e
   * @returns {Promise<void>}
   */
  async #updateForm(e) {
    if (e.detail.name === "") return;
    let updatedData = {};
    if (e.detail.name === "food_preferences") {
      updatedData = this.#updateFoodPreferences(e);
    } else if (e.detail.name === "goals") {
      updatedData = this.#updateGoalsPreferences(e);
    } else if (e.detail.name === 'privacy') {
      if (e.detail.value !== undefined) {
        updatedData = e.detail.value ? {privacy: 'private'} : {privacy: 'public'};
      }
    } else {
      if (e.detail.name === "x-form-data") return; // Ignore initial event.
      updatedData[e.detail.name] = e.detail.value;
    }

    const task = async () => {
      await this.#domain.updateProfile(updatedData);
      if (app.session.user) {
        app.session[e.detail.name] = e.detail.value;
      }
      await app.session.refreshUser(true);
    };

    return Task.run(task, {
      ghost: e.target,
      description: "Updating profile",
    });
  }

  async connectedCallback() {
    await super.connectedCallback();
    app.session.on("profile-updated", () => {
      this.requestUpdate();
    });
    app.session.refreshUser();
    try {
      this.#topics = (await this.#domain.getAllPossibleTopics()).data;
    } catch {
      // ignore
    }
  }

  renderPage() {
    return html`
      <section class="hero hide-full-width">
        <h1>Profile</h1>
      </section>

      ${this.renderProgress()} ${this.renderProfile()}

      <section class="card">
        <button
          type="button"
          class="primary wide outline mb-tiny"
          @click="${() => {
            window.location.replace(
              `/profile/${app.session.user?.slug ?? app.session.user?.id}`
            );
          }}"
        >
          Show public profile
        </button>

        <button
          name="logout"
          class="primary wide yellow"
          type="button"
          @click="${this.#logout}"
        >
          Sign out
        </button>
      </section>
    `;
  }

  renderWidget() {
    return this.renderProgress(true);
  }

  renderProfile() {
    const goalList = app.session?.user?.interest_topics?.map((t) => t.name);
    let goals = "";
    if (goalList && goalList.length > 0) {
      goals = goalList.join(", ");
    }

    return html`
      <h2 class="center" id="settings">Profile picture</h2>
      <section class="card">
        <div class="profile-picture">
          <figure>
            <img
              src="${app.session?.user?.profile_img_url ||
              "/assets/img/profile-picture.webp"}"
              alt="User profile picture"
              loading="lazy"
            />
          </figure>

          <div class="options">
            <button
              class="wide"
              @click=${(e) => e.target?.querySelector("input")?.click()}
            >
              Change profile picture
              <input
                type="file"
                style="display:none;"
                name="profile_upload"
                accept="image/*"
                @change=${this.#handleUploadImage}
              />
            </button>

            <button
              class="wide simple red"
              @click=${this.#deleteProfilePicture}
              ?disabled=${!app.session?.user?.profile_img_url}
            >
              <svg-icon icon="trash" size="18px"></svg-icon>
              Delete this picture
            </button>
          </div>
        </div>
      </section>

      <h2 class="center">Profile details</h2>
      <section class="card account">
        <x-form
          live
          @statechange=${throttle(this.#updateForm.bind(this), 1000)}
          @action=${this.#updateForm}
        >
          <form action="" method="post" class="material">
            <input
              name="firstname"
              data-label="First name"
              required
              value=${app.session?.user?.firstname}
            />

            <input
              name="lastname"
              data-label="Last name"
              required
              value=${app.session?.user?.lastname}
            />

            <label>
              <span data-label="">Gender</span>
              <select name="sexe" class="mb-tiny">
                <option value="">-- Select sex --</option>
                <option value="1" ?selected=${app.session?.user?.sexe === 1}>
                  Male
                </option>
                <option value="2" ?selected=${app.session?.user?.sexe === 2}>
                  Female
                </option>
                <option value="3" ?selected=${app.session?.user?.sexe === 3}>
                  Prefer not to say
                </option>
              </select>
              <svg-icon icon="caret" size="10px"></svg-icon>
            </label>

            <manage-emails></manage-emails>

            <label>
              <span data-label>Job</span>
              <jobs-autocomplete
                name="job"
                value="${app.session?.user?.job}"
              ></jobs-autocomplete>
            </label>

            <label>
              <span data-label>Allergies</span>
              <tag-list
                name="food_preferences"
                .value=${this.#foodPreferencesValue()}
                .autocomplete=${this.allergiesAutoComplete}
              ></tag-list>
            </label>

            <label style="margin-top: -28px">
              <number-stepper name="family_size" .value=${app.session?.user?.family_size || 2} min=1 max=13 label="Family Size"></number-stepper>
            </label>

            <switch-check name="privacy" text="Set profile to private" .value=${app.session.user.privacy === 'private'} rtl></switch-check>
            <small>When your profile is set to private your last name is capped to one letter and your bio is not visible to others.</small>
          </form>
        </x-form>
      </section>

      <h2 class="center">Biography</h2>
      <section class="card bio">
        <x-form
          live
          @statechange=${throttle(this.#updateForm.bind(this), 1000)}
          @action=${this.#updateForm}
        >
          <form action="" method="post" class="material">
            <label>
              Your profile biography
              <textarea name="bio_short" rows="4">${app.session?.user?.bio_short}</textarea>
            </label>
          </form>
        </x-form>
      </section>

      <h2 class="center">Health details</h2>
      <section class="card health">
        <x-form
          live
          @statechange=${throttle(this.#updateForm.bind(this), 1000)}
          @action=${this.#updateForm}
        >
          <form action="" method="post" class="material">
            <tag-list
              name="goals"
              .value=${this.#goalsValue()}
              .autocomplete=${this.goalsAutoComplete}
            ></tag-list>

            <input
              name="body_length"
              data-label="Length"
              type="number"
              value=${Math.trunc(app.session?.user?.body_length)}
            />

            <input
              name="body_weight"
              data-label="Weight"
              type="number"
              value=${Math.trunc(app.session?.user?.body_weight)}
            />

            <input
              name="date_of_birth"
              data-label="Date of birth"
              type="date"
              value=${app.session?.user.date_of_birth}
            />
          </form>
        </x-form>
      </section>
    `;
  }

  getWidgetSettings() {
    const defaultSettings = super.getWidgetSettings();

    const progress = app.session.user.progress;
    const totalPercentage = (progress.total * 100).toFixed(0);
    const dynamicPrio = 12 * (100 - totalPercentage);
    return {
      ...defaultSettings,
      priority: dynamicPrio,
      full: totalPercentage < 30,
      title: "Profile Progress",
    };
  }

  renderProgress(isWidget) {
    if (!app.session.user?.progress) return nothing;
    const progress = app.session.user.progress;
    const totalPercentage = (progress.total * 100).toFixed(0);

    return html` ${isWidget
        ? ""
        : html`<h2 class="center">Profile progress</h2>`}
      <section class="card">
        <profile-progress>
          <div class="wrap">
            <progress-indicator
              type="circle"
              value=${totalPercentage}
              theme="${progress.total < 1.0 ? `yellow` : `green`}"
              size="50"
              bg="beige"
            >
            </progress-indicator>

            <div class="info" style="margin-left: var(--gutter-tiny)">
              <h5>
                <strong>Your profile is ${totalPercentage}% completed.</strong>
              </h5>
              <p>
                ${progress.total < 1.0
                  ? html` Ready to make it 100%? `
                  : html` Amazing work reaching 100%! `}
              </p>
            </div>
          </div>

          <a class="progress-wrap" href="/account#settings">
            <h4>Personal details &rang;</h4>

            <progress-indicator
              type="circle"
              value=${Number(progress.profile * 100).toFixed(0)}
              theme="${progress.profile <= 0.5
                ? `red`
                : progress.profile === 1.0
                ? `green`
                : `yellow`}"
              bg="white"
            >
            </progress-indicator>
          </a>

          <a class="progress-wrap" href="/braincheck">
            <h4>BrainCheck &rang;</h4>

            <progress-indicator
              type="circle"
              value=${Number(progress.braincheck * 100).toFixed(0)}
              theme="${progress.braincheck <= 0.5 ? `red` : `green`}"
              bg="white"
            >
            </progress-indicator>
          </a>

          <a class="progress-wrap" href="/healthcheck">
            <h4>HealthCheck &rang;</h4>

            <progress-indicator
              type="circle"
              value=${Number(progress.healthcheck * 100).toFixed(0)}
              theme="${progress.healthcheck <= 0.5 ? `red` : `green`}"
              bg="white"
            >
            </progress-indicator>
          </a>
        </profile-progress>
      </section>`;
  }

  async #logout() {
    await app.session.logout();
    window.location.href = "/";
  }
}
