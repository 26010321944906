import {html, LitElement} from "lit";
import {createRef, ref} from "lit/directives/ref.js";
import {AutoComplete} from "@qogni-technologies/design-system/src/components/base/form/auto-complete";
import { detectPersonNames } from "../common";

customElements.define(
  "connections-autocomplete",
  class ConnectionsAutocomplete extends LitElement {
    #inputRef = createRef();
    static formAssociated = true;

    createRenderRoot() {
      return this;
    }

    static get properties() {
      return {
        userList: {type: Array},
        allowInvitations: {type: Boolean, attribute: "allow-invitations"},
        foreignObjectName: {type: String, attribute: "object-name"},
        foreignObjectType: {type: String, attribute: "object-type"},
        foreignObjectId: {type: String, attribute: "object-id"},
        placeholder: {type: String, attribute: true},
        name: {type: String, attribute: true},
        required: {type: Boolean, attribute: true},
        value: {type: String, attribute: true},
      };
    }

    constructor() {
      super();
      this.internals_ = this.attachInternals()
      this.allowInvitations = false;
    }

    render() {
      return html`<input
      type="search"
      ?required=${this.required}
      placeholder="${this.placeholder}"
      name="${this.name}"
      value="${this.value}"
      ${ref(this.#inputRef)}
      @focus=${(e) => AutoComplete.connect(e, this.connectionsAutoComplete)}
      @input=${(e) => e.stopPropagation()}
      @result-selected=${(e) => this.dispatchEvent(new CustomEvent('result-selected', {detail: e.detail}))}
    />`;
    }

    get connectionsAutoComplete() {
      return {
        categories: {
          Connections: {
            sortIndex: 1,
            trigger: (options) => {
              return options.search.length >= 0;
            },
            action: (options) => {
              if (options.invite) {
                location.href = `/benefits/share?${new URLSearchParams({
                  invite: btoa(options.inviteData),
                  sourceId: btoa(this.foreignObjectId),
                  sourceType: this.foreignObjectType,
                })}`;
              } else if (options?.text) {
                this.#inputRef.value.value = options.text;
              }
            },
            getItems: async (options) => {
              const regexPattern = new RegExp(options.search, "gi");
              const results = this.userList.map(user => {
                return {
                  ...user,
                  text: user.firstname + " " + user.lastname,
                  image: user.profile_img_url ?? "/assets/img/profile-picture.webp"
                }
              }).filter(user => user.text.match(regexPattern));

              if (!results || results.length === 0) {
                const names = detectPersonNames(options.search);

                if (names.length) {
                  return [
                    {
                      icon: "diamond",
                      inviteData: names[0],
                      text: `Invite ${names[0]} to ${this.foreignObjectName ? `your ${this.foreignObjectName}` : 'Qogni'}?`,
                      description: `Accepted invites get you 40 points! ${this.foreignObjectName ? `And will add this person to your ${this.foreignObjectName}` : ''}`,
                      category: "Invite",
                      invite: true,
                    },
                  ];
                } else {
                  return [{
                    text: 'Get started by creating connections first. You can only start chatting with people you have connections with.',
                    icon: 'account'
                  }];
                }
              }

              return results;
            }
          }
        }
      }
    }
  }
);
