import { html, LitElement } from "lit";
import { AccountDomain } from "../../domain/account-domain";
import { AutoComplete } from "@qogni-technologies/design-system/src/components/base/form/auto-complete";
import { createRef, ref } from "lit/directives/ref.js";

/**
 * Autocomplete element for Jobs
 */
customElements.define(
  "jobs-autocomplete",
  class TextAutocomplete extends LitElement {
    #accountDomain;
    #jobs = {};
    #inputRef = createRef();

    static get properties() {
      return {
        placeholder: { type: String, attribute: true },
        name: { type: String, attribute: true },
        required: { type: Boolean, attribute: true },
        value: { type: String, attribute: true },
      };
    }

    static formAssociated = true;

    constructor() {
      super();
      this.internals_ = this.attachInternals();
      this.#accountDomain = new AccountDomain();

      this.addEventListener("category-selected", (e) => {
        const input = e.target.querySelector(".ac-input");
        // Prevent Saving because the category was selected
        input.addEventListener(
          "change",
          (e) => {
            e.preventDefault();
            e.stopPropagation();
          },
          {
            once: true,
          }
        );
        setTimeout(() => {
          input.focus();
        }, 100);
      });
    }

    render() {
      return html`<input
        type="search"
        ?required=${this.required}
        placeholder="${this.placeholder}"
        name="${this.name}"
        value="${this.value}"
        ${ref(this.#inputRef)}
        @focus=${(e) => AutoComplete.connect(e, this.jobsAutoComplete)}
        @input=${(e) => e.stopPropagation()}
      />`;
    }

    createRenderRoot() {
      return this;
    }

    get jobsAutoComplete() {
      return {
        //debug: true,
        hideCategory: true,
        categories: {
          Jobs: {
            sortIndex: 1,
            trigger: (options) => {
              return options.search.length >= 2;
            },
            action: (options) => {
              this.#inputRef.value.value = options.text;
            },
            getItems: async (options) => {
              const matches = [];
              const regexPattern = new RegExp(options.search, "gi"); // 'g' for global search, 'i' for case-insensitive
              if (!this.#jobs || Object.keys(this.#jobs).length === 0)
                this.#jobs = await this.#accountDomain.getJobList();
              for (const category of Object.keys(this.#jobs)) {
                const catMatches = this.#jobs[category].filter((i) => {
                  return regexPattern.test(i) || regexPattern.test(category);
                });

                if (catMatches.length)
                  matches.push(
                    ...catMatches.map((i) => {
                      return {
                        description: category,
                        text: i,
                      };
                    })
                  );
              }
              return matches.sort((a, b) => {
                return a.text < b.text ? 1 : -1;
              });
            },
          },
          Groups: {
            sortIndex: 2,
            trigger: (options) => {
              return options.search.length === 0;
            },
            action: (options) => {
              this.dispatchEvent(new CustomEvent("category-selected"));
              this.#inputRef.value.value = options.text;
            },
            getItems: async (options) => {
              const matches = [];
              const regexPattern = new RegExp(options.search, "gi"); // 'g' for global search, 'i' for case-insensitive
              if (!this.#jobs || Object.keys(this.#jobs).length === 0)
                this.#jobs = await this.#accountDomain.getJobList();
              for (const category of Object.keys(this.#jobs)) {
                if (regexPattern.test(category)) {
                  matches.push(category);
                }
              }
              const sorted = matches.sort().reverse();

              return sorted.map((i) => {
                return { text: i };
              });
            },
          },
        },
      };
    }
  }
);
