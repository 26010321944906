import { html } from "lit";
import {
  AuthenticatedMixin,
  FullscreenMixin,
  PWAPage,
} from "../shared/pwa-page";
import { Task } from "@qogni-technologies/pwa-utils-library/src/utils/task";
import { repeat } from "lit/directives/repeat.js";
import { range } from "lit/directives/range.js";
import { AccountDomain, Languages } from "../domain/account-domain";
import {config} from "../qogni-app-config";

export class OnboardingPage extends FullscreenMixin(
  AuthenticatedMixin(PWAPage)
) {
  /** @var {AccountDomain} #domain */
  #domain;
  #step = 1;
  #maxSteps = 3;

  #topics = undefined;

  constructor() {
    super();
    this.#domain = new AccountDomain();
  }

  #stepBack() {
    if (this.#step <= 1) return;

    this.#step -= 1;
    this.requestUpdate();
  }

  /**
   * Handles x-form's action event.
   * @param {*} e
   */
  async action(e) {
    e.preventDefault();
    const action = `${e.detail.submitter?.name}`;
    if (!action || action === "undefined") return;

    const task = async () => {
      switch (this.#step) {
        case 1:
          if (!e.detail.value.sexe) {
            app.addToastMessage(
              "Please select a correct sex, we need this to optimize your experience and ask the right questions to you during our BrainCheck and HealthCheck.",
              { type: "warning" }
            );
            return;
          }
          if (!e.detail.value.job) {
            app.addToastMessage(
              "Please select a job from the job list. Start typing in the Job field in order to select a job.",
              { type: "warning" }
            );
            return;
          }
          await this.#domain.updateProfile({
            firstname: e.detail.value.firstname,
            lastname: e.detail.value.lastname,
            sexe: parseInt(e.detail.value.sexe) || null,
            language: e.detail.value.language,
            job: e.detail.value.job,
          });
          break;
        case 2:
          await this.#domain.updateProfile({
            date_of_birth: e.detail.value.date_of_birth,
            body_length: e.detail.value.body_length.replace(",", "."),
            body_weight: e.detail.value.body_weight,
          });
          break;
        case 3:
          if (!e.detail.value.topics || e.detail.value.topics.length < 1) {
            app.addToastMessage(
              "Please select at least one topic from the list.",
              { type: "warning" }
            );
            return;
          }

          await this.#domain.updateProfile({
            interest_topics: e.detail.value.topics,
          });
          break;
        default:
          break;
      }

      // In any case we want to refresh the user in the session storage.
      await app.session.refreshUser(true);

      if (action === 'close' && this.#step === 1) {
        location.replace("/?from=onboarding");
        return;
      }

      this.#step++;
      if (this.#step === 3) return this.#loadTopics(); // Will also cause an update and is async. so we can return.
      if (this.#step === 4) {
        if (action === 'close') {
          location.replace("/?from=onboarding");
          return;
        } else {
          location.replace("/braincheck?from=onboarding");
          return;
        }
      }
      this.requestUpdate();
    };

    Task.run(task, {
      ghost: e?.target,
      description: "Update user details",
    });
  }

  async connectedCallback() {
    await super.connectedCallback();
    await app.session.refreshUser();

    // Detect user language if not set. If not and the browser languages in order has one that we support, set that one.
    if (app.session.user && app.session.user?.language === null) {
      for (const language of navigator.languages) {
        if (Languages.filter((v) => v.value === language).length === 1) {
          this.#domain.updateProfile({ language: language });
          app.session.user.language = language;
          break;
        }
      }
    }

    if (
      app.session.user?.firstname &&
      app.session.user?.lastname &&
      app.session.user?.sexe !== null &&
      app.session.user?.language !== null &&
      app.session.user?.job !== null
    ) {
      this.#step = 2;
      this.requestUpdate();
    } else {
      this.#step = 1;
      return;
    }
    if (
      app.session.user?.date_of_birth &&
      app.session.user?.body_length &&
      app.session.user?.body_weight
    ) {
      this.#step = 3;
      this.requestUpdate();
      await app.session.refreshUser();
      this.#loadTopics();
    }

    this.requestUpdate();
  }

  #renderDetails() {
    return html`
      <x-form @action=${this.action}>
        <form method="get" action="" class="material">
          <fieldset>
            <legend>
              <p>
                We would like to know some more personal details, these are
                optional for now, but will fine-tune your experience.
              </p>
            </legend>
            <input
              name="date_of_birth"
              data-label="Date of birth"
              type="date"
              required
              placeholder="Date of birth"
              value=${app.session?.user?.date_of_birth}
            />

            <input
              name="body_length"
              data-label="Height"
              data-icon="cm"
              data-icon-color="#7ec6fa"
              data-icon-size="28px"
              type="text"
              required
              step=".01"
              pattern="[0-9]+([\\.,][0-9]+)?"
              placeholder="Height"
              value=${app.session?.user?.body_length ? Math.trunc(app.session?.user?.body_length) : ''}
            />

            <input
              name="body_weight"
              data-label="Weight"
              data-icon="kg"
              data-icon-color="#7ec6fa"
              data-icon-size="25px"
              type="number"
              required
              placeholder="Weight"
              value=${app.session?.user?.body_weight ? Math.trunc(app.session?.user?.body_weight) : ''}
            />

            <flex-container>
              <flex-item class="col-6">
                <button
                  name="back"
                  type="button"
                  @click=${this.#stepBack}
                  class="outline wide"
                >
                  Step back
                </button>
              </flex-item>
              <flex-item class="col-6">
                <button name="continue" type="submit" class="wide">
                  Continue
                </button>
              </flex-item>
            </flex-container>
          </fieldset>
        </form>
      </x-form>
    `;
  }

  #renderName() {
    return html`
      <x-form @action=${this.action}>
        <form method="get" action="" class="material">
          <fieldset>
            <legend>
              <p>
                We would like to know your name in order to address you more
                personally.
              </p>
            </legend>

            <input
              name="firstname"
              data-label="First name"
              type="text"
              required
              placeholder="First name"
              value=${app.session?.user?.firstname}
            />

            <input
              name="lastname"
              data-label="Last name"
              type="text"
              required
              placeholder="Last name"
              value=${app.session?.user?.lastname}
            />

            <label>
              <span data-label="">Gender</span>
              <select name="sexe" class="mb-tiny">
                <option value="">-- Select sex --</option>
                <option value="1" ?selected=${app.session?.user?.sexe === 1}>
                  Male
                </option>
                <option value="2" ?selected=${app.session?.user?.sexe === 2}>
                  Female
                </option>
                <option value="3" ?selected=${app.session?.user?.sexe === 3}>
                  Prefer not to say
                </option>
              </select>
              <svg-icon icon="caret" size="10px"></svg-icon>
            </label>

            <label>
              <span data-label="">Language</span>
              <select name="language" required>
                <option value="">-- Select language --</option>
                ${Languages.map(
                  (lang) => html`
                    <option
                      value="${lang.value}"
                      ?selected=${app.session?.user?.language === lang.value}
                    >
                      ${lang.label}
                    </option>
                  `
                )}
              </select>
              <svg-icon icon="caret" size="10px"></svg-icon>
            </label>

            <label>
              <span data-label>Job</span>
              <jobs-autocomplete
                name="job"
                value="${app.session?.user?.job}"
                required
              ></jobs-autocomplete>
            </label>

            <flex-container>
              <flex-item class="col-12">
                <button name="continue" type="submit" class="wide">
                  Continue
                </button>
              </flex-item>
            </flex-container>
          </fieldset>
          <p>
            <strong>Committed to data security and your privacy.</strong><br>
            Your information stays private. We never sell your personal data. It’s used exclusively to provide you with tailored content and optimize your personal programs on Qogni.
            For more information please take a look at our <a href="${config.privacyPolicyUrl}" target="_blank">Privacy Policy</a> and <a href="${config.tosUrl}" target="_blank">Terms of Service</a>.
          </p>
        </form>
      </x-form>
    `;
  }

  #renderTopics() {
    return html`
      <x-form @action=${this.action}>
        <form method="get" action="" class="">
          <fieldset>
            <legend>
              <p>
                Which of these topics are relevant for you? Select one or more
                topics.
              </p>
            </legend>

            ${this.#topics === undefined ? html`
              <app-shimmer class="title mb-small"></app-shimmer>
              <app-shimmer class="title mb-small"></app-shimmer>
              <app-shimmer class="title mb-small"></app-shimmer>
              <app-shimmer class="title mb-small"></app-shimmer>
              <app-shimmer class="title mb-small"></app-shimmer>
              <app-shimmer class="title mb-small"></app-shimmer>
              <app-shimmer class="title mb-small"></app-shimmer>
            ` : html`
              <multi-select>
                ${repeat(
                  this.#topics,
                  (t) => html`
                  <label>
                    <input
                      type="checkbox"
                      name="topics[]"
                      value="${t.id}"
                      ?checked=${t.checked}
                    />
                    <span data-label="">${t.name}</span>
                  </label>
                `
                )}
              </multi-select>
            `}

            <button
              name="close"
              type="submit"
              class="outline wide"
              style="margin-top: var(--gutter-small)"
            >
              Save and close
            </button>
            <button
              name="continue"
              type="submit"
              class="wide"
              style="margin-top: var(--gutter-small)"
            >
              Continue to the Braincheck
            </button>
          </fieldset>
        </form>
      </x-form>
    `;
  }

  render() {
    let stepContent;
    switch (this.#step) {
      case 1:
      default:
        stepContent = this.#renderName();
        break;
      case 2:
        stepContent = this.#renderDetails();
        break;
      case 3:
        stepContent = this.#renderTopics();
        break;
    }

    return html`
      <onboarding-container>
        <div class="center">
          <h1>Almost there!</h1>
          <p class="light">To start your journey we need extra information</p>
        </div>

        <step-indicator>
          ${repeat(
            range(this.#maxSteps),
            (nr) => html`
              <a
                href="#"
                ?data-current=${this.#step === nr + 1}
                data-step="${nr + 1}"
                @click=${this.#gotoStep}
                ?data-finished=${this.#step > nr + 1}
              >
                Step ${nr + 1}
              </a>
            `
          )}
        </step-indicator>

        <section class="card">${stepContent}</section>

        <img
          class="footer"
          src="/assets/img/boy-with-arrow.svg"
          loading="lazy"
        />
      </onboarding-container>
    `;
  }

  #loadTopics() {
    const task = async () => {
      function waitForArray() {
        return new Promise((resolve) => {
          const checkArray = () => {
            if (app.session?.user?.interest_topics) return resolve();
            setTimeout(checkArray, 50);
          };
          checkArray();
        });
      }
      try {
        const response = await this.#domain.getAllPossibleTopics();
        this.#topics = response.data;
        await waitForArray();
        // Check if we already have them preselected.
        const currentTopics = app.session?.user?.interest_topics ?? [];
        this.#topics.forEach((topic, idx) => {
          this.#topics[idx]["checked"] =
            currentTopics.filter((t) => topic.id === t.id).length > 0;
        });
        this.requestUpdate();
      } catch {
        app.addToastMessage("No topics found. Please try again later.", {
          type: "error",
        });
      }
    };

    Task.run(task, {
      ghost: this.renderRoot,
      description: "Fetch all available topics...",
    });
  }

  #gotoStep(e) {
    e.stopPropagation();
    e.preventDefault();
    const step = parseInt(e.target.getAttribute("data-step"));
    if (
      isNaN(step) ||
      step < 0 ||
      step > this.#maxSteps ||
      step === this.#step ||
      step > this.#step
    )
      return;
    this.#step = step;
    this.requestUpdate();
  }
}
